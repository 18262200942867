export const cropImage = (base64Image, annotations) => {
    return new Promise((resolve) => {
        const image = new Image();
        image.onload = () => {
            const canvas = document.createElement('canvas');
            const context = canvas.getContext('2d');

            // Calculer la zone de recadrage en fonction des annotations
            const allVertices = annotations.flatMap(annotation => annotation.boundingPoly.vertices);
            const minX = Math.min(...allVertices.map(vertex => vertex.x));
            const minY = Math.min(...allVertices.map(vertex => vertex.y));
            const maxX = Math.max(...allVertices.map(vertex => vertex.x));
            const maxY = Math.max(...allVertices.map(vertex => vertex.y));
            const cropWidth = maxX - minX;
            const cropHeight = maxY - minY;

            // Définir la taille du canvas recadré
            canvas.width = cropWidth;
            canvas.height = cropHeight;

            // Dessiner l'image recadrée sur le nouveau canvas
            context.drawImage(image, minX, minY, cropWidth, cropHeight, 0, 0, cropWidth, cropHeight);

            // Convertir le nouveau canvas en image base64
            const croppedBase64Image = canvas.toDataURL('image/jpeg');
            resolve(croppedBase64Image);
        };
        image.src = base64Image;
    });
};