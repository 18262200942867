
/*AIzaSyCu3z5d_en83mzl7hyss9jkFDTGzONTor0*/
const CloudVision = async (base64data) => {
    const base64Image = base64data.split(',')[1];
    try {
        const response = await fetch(
            'https://vision.googleapis.com/v1/images:annotate?key=AIzaSyCu3z5d_en83mzl7hyss9jkFDTGzONTor0',
            {
                method: 'POST',
                body: JSON.stringify({
                    requests: [
                        {
                            image: {
                                content: base64Image,
                            },
                            features: [
                                {
                                    type: 'TEXT_DETECTION',
                                },
                            ],
                        },
                    ],
                }),
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        );
        const data = await response.json();
        if (data.responses && data.responses[0].textAnnotations) {
            const padding = 10; // Nombre de pixels pour agrandir la bounding box

            // Filtrer les annotations pour ne retourner que celles avec une description égale à "0,00" ou "0.00"
            const filteredAnnotations = data.responses[0].textAnnotations.filter(annotation => {
                return /^(0,00|0\.00)$/.test(annotation.description);
            }).map(annotation => ({
                boundingPoly: {
                    vertices: annotation.boundingPoly.vertices.map((vertex, index) => {
                        switch (index) {
                            case 0: // Haut-gauche
                                return { x: vertex.x - padding, y: vertex.y - padding };
                            case 1: // Haut-droit
                                return { x: vertex.x + padding, y: vertex.y - padding };
                            case 2: // Bas-droit
                                return { x: vertex.x + padding, y: vertex.y + padding };
                            case 3: // Bas-gauche
                                return { x: vertex.x - padding, y: vertex.y + padding };
                            default:
                                return vertex;
                        }
                    })
                },
                description: annotation.description
            }));
            return filteredAnnotations; // Retourner les annotations filtrées et ajustées
        } else {
            console.log(`Aucune annotation de texte détectée dans l'image ${data}`);
            return []; // Retourner un tableau vide si aucune annotation n'est détectée
        }
    } catch (error) {
        console.error('Erreur lors de la détection de texte :', error);
        throw error; // Relancer l'erreur pour que l'appelant puisse la gérer
    }
};

export default CloudVision;
