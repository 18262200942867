import React from 'react';
import styles from './help.module.css';
import "../../App.css"

const Help = () => {
    return (
        <div className={styles.container}>
            <div className={styles.box}>
                <h1>Besoin d'aide</h1>
                <br/>
                <p className={styles.p}><strong>Email : </strong>pierre.barat@yahoo.com</p>
            </div>
        </div>
    );
};

export default Help;
