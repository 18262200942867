import React from 'react';
import styles from '../privacy/privacyPolicy.module.css';


const PrivacyPolicy = () => {
    return (
        <div className={styles.container}>
            <div className={styles.box}>
                <div className={styles.paper}>
                    <h1 className={styles.heading}>Politique de confidentialité</h1>
                    <p className={styles.paragraph}>
                        Votre vie privée est très importante pour nous. Cette politique de confidentialité explique comment nous gérons et protégeons vos informations personnelles lors de l'utilisation de notre application.
                    </p>
                    <h2 className={styles.subHeading}>Authentification anonyme</h2>
                    <p className={styles.paragraph}>
                        Notre application Perka utilise uniquement l'authentification anonyme. Cela signifie que vous pouvez utiliser notre application sans avoir à fournir des informations personnelles telles que votre adresse email ou tout autre identifiant personnel.
                    </p>
                    <h2 className={styles.subHeading}>Collecte et utilisation des données</h2>
                    <p className={styles.paragraph}>
                        Nous ne collectons, ne stockons, ni n'utilisons aucune donnée personnelle ou extérieure à votre appareil. Aucune donnée analytique n'est collectée par notre application.
                    </p>
                    <h2 className={styles.subHeading}>Partage des données</h2>
                    <p className={styles.paragraph}>
                        Nous ne partageons, ne vendons, ni n'échangeons aucune donnée utilisateur avec des tiers. Votre utilisation de notre application est totalement confidentielle.
                    </p>
                    <h2 className={styles.subHeading}>Sécurité</h2>
                    <p className={styles.paragraph}>
                        Nous prenons des mesures raisonnables pour protéger vos informations contre la perte, l'utilisation abusive et l'accès non autorisé. Cependant, aucune méthode de transmission sur Internet ou de stockage électronique n'est totalement sécurisée.
                    </p>
                    <h2 className={styles.subHeading}>Modifications de cette politique de confidentialité</h2>
                    <p className={styles.paragraph}>
                        Nous nous réservons le droit de mettre à jour cette politique de confidentialité à tout moment. Nous vous informerons de tout changement en publiant la nouvelle politique de confidentialité sur cette page. Nous vous conseillons de consulter régulièrement cette politique de confidentialité pour toute mise à jour.
                    </p>
                    <h2 className={styles.subHeading}>Contact</h2>
                    <p className={styles.paragraph}>
                        Si vous avez des questions concernant cette politique de confidentialité, veuillez nous contacter à l'adresse suivante : [votre email ou formulaire de contact].
                    </p>
                </div>
            </div>
        </div>
    );
};

export default PrivacyPolicy;
