import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Help from './views/help/help';
import Home from "./views/home/home";
import PrivacyPolicy from "./views/privacy/privacyPolicy";

const App = () => {
    return (
        <Router>
            <Routes>
                <Route exact path="/5FOqoVDPMPWdmyYeZcHeBrbPSlk2" element={<Home />} />
                <Route exact path="/help" element={<Help />} />
                <Route exact path="/privacy" element={<PrivacyPolicy />} />
            </Routes>
        </Router>
    );
};

export default App;
