import React, {useRef, useEffect, useState, useCallback} from 'react';
import CloudVision from "../../utils/api/cloudVision";
import {cropImage} from "../../utils/crop";
import {extractText} from "./base64ToText";
import {db} from "../../firebase";
import {collection, doc, getDoc, updateDoc} from "firebase/firestore";


const WebcamComponent = ({currentClient}) => {
    const [totalAmount, setTotalAmount] = useState(0);
    const [clickPosition, setClickPosition] = useState({x: 0, y: 0});
    const [devices, setDevices] = useState([]);
    const [base64Data, setBase64Data] = useState(null);
    const [selectedDevice, setSelectedDevice] = useState(null);
    const [annotations, setAnnotations] = useState([]);
    const videoRef = useRef(null);
    const canvasRef = useRef(null);


    /*  function zeroString(input) {
          const match = input.match(zeroRegex);
          return match ? match[0] : '';
      }*/

    const deleteCurrentClient = async () => {
        const fieldRef = doc(db, 'users', '5FOqoVDPMPWdmyYeZcHeBrbPSlk2');
        try {
            await updateDoc(fieldRef, {
                currentClient: ''
            });
        } catch (error) {
            console.log(error);
        }
    }

    function containsZero(value) {
        // This regex matches "0" or "0.0", "0.00", "0.000", etc., with both dot and comma as decimal separators
        const regex = /^0([.,]0+)?$/;
        return regex.test(value.trim());
    }

    const saveTotal = async (total) => {
        const usersRef = collection(db, 'users');
        const docRef = doc(usersRef, "5FOqoVDPMPWdmyYeZcHeBrbPSlk2");

        try {
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                const currentValue = docSnap.data().yourField || 0; // Get the current value, 0 if it doesn't exist
                const newValue = currentValue + total;

                await updateDoc(docRef, {
                    yourField: newValue
                });
                await deleteCurrentClient();
                console.log("Document successfully updated!");
            } else {
                console.log("No such document!");
            }
        } catch (error) {
            console.error("Error updating document: ", error);
        }
    };


    useEffect(() => {
        const fetchData = async () => {
            while (annotations.length === 1 && currentClient !== "") {
                console.warn("fetchData");
                const base64Data = await referenceFrame(0);
                const croppedImage = await cropImage(base64Data, annotations);
                const extractedAmount = await extractText(croppedImage);
                console.log(`Extracted amount is zero: ${extractedAmount}`);
                console.log(`Current total amount: ${totalAmount}`);
                console.warn(containsZero(extractedAmount))
                console.warn(containsZero(extractedAmount) && totalAmount !== 0)
                if (containsZero(extractedAmount)) {
                    console.log("total", totalAmount);
                    await saveTotal(totalAmount);
                } else {
                    setTotalAmount(prevTotalAmount => {
                        console.log("Updating totalAmount from", prevTotalAmount, "to", extractedAmount);
                        return extractedAmount;
                    });
                }
            }
        };

        fetchData();
    }, [currentClient, annotations,]);


    useEffect(() => {
        const getDevices = async () => {
            const devices = await navigator.mediaDevices.enumerateDevices();
            const cameras = devices.filter(device => device.kind === 'videoinput');
            setDevices(cameras);
            setSelectedDevice(cameras[0]);
            /*
                        setSelectedDevice(cameras[1]);
            */
        };

        getDevices();
    }, []);

    useEffect(() => {
        const startVideoStream = async () => {
            if (!selectedDevice) return;
            try {
                const stream = await navigator.mediaDevices.getUserMedia({
                    video: {deviceId: {exact: selectedDevice.deviceId}}
                });
                videoRef.current.srcObject = stream;
                videoRef.current.onloadedmetadata = () => {
                    if (canvasRef.current) {
                        canvasRef.current.width = videoRef.current.videoWidth;
                        canvasRef.current.height = videoRef.current.videoHeight;
                    }
                    reFrame(2000);
                };
            } catch (error) {
                console.error('Une erreur est survenue :', error);
            }
        };
        startVideoStream();

        return () => {
            if (videoRef.current && videoRef.current.srcObject) {
                const stream = videoRef.current.srcObject;
                const tracks = stream.getTracks();
                tracks.forEach(track => track.stop());
            }
        };
    }, [selectedDevice]);

    useEffect(() => {
        captureFrame(annotations);
    }, [annotations]);


    const toggleCamera = useCallback(() => {
        console.log(devices.length);
        if (devices.length === 0) {
            // make ui error message
            console.warn("Aucun périphérique vidéo trouvé.");
            return;
        }
        const currentIndex = devices.findIndex(device => device.deviceId === selectedDevice.deviceId);
        const nextIndex = (currentIndex + 1) % devices.length;
        setSelectedDevice(devices[nextIndex]);
    }, [devices, selectedDevice]);

    const reFrame = useCallback(async (time) => {
        const data = await referenceFrame(time);
        const base64data = data;
        const newAnnotations = await CloudVision(base64data);
        setBase64Data(base64data);
        setAnnotations(newAnnotations);
    }, []);

    const referenceFrame = useCallback(async (time) => {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                if (videoRef.current) {
                    const video = videoRef.current;

                    const tempCanvas = document.createElement('canvas');
                    tempCanvas.width = video.videoWidth;
                    tempCanvas.height = video.videoHeight;
                    const context = tempCanvas.getContext('2d');

                    context.drawImage(video, 0, 0, tempCanvas.width, tempCanvas.height);
                    const imageDataURI = tempCanvas.toDataURL('image/jpeg');
                    resolve(imageDataURI);
                } else {
                    reject(new Error('La référence vidéo est manquante.'));
                }
            }, time);
        });
    }, []);


    const captureFrame = useCallback(async (annotations) => {
        if (videoRef.current && canvasRef.current) {
            const video = videoRef.current;
            const canvas = canvasRef.current;
            const context = canvas.getContext('2d');

            context.drawImage(video, 0, 0, canvas.width, canvas.height);

            const videoWidth = video.videoWidth;
            const videoHeight = video.videoHeight;
            const scaleX = canvas.width / videoWidth;
            const scaleY = canvas.height / videoHeight;

            if (annotations) {
                context.clearRect(0, 0, canvas.width, canvas.height);
                context.strokeStyle = 'lightgreen';
                context.lineWidth = 2;

                annotations.forEach(annotation => {
                    const vertices = annotation.boundingPoly.vertices;
                    context.beginPath();
                    context.moveTo(vertices[0].x * scaleX, vertices[0].y * scaleY);
                    for (let i = 1; i < vertices.length; i++) {
                        context.lineTo(vertices[i].x * scaleX, vertices[i].y * scaleY);
                    }
                    context.closePath();
                    context.stroke();
                });

                /* if (annotations.length === 1) {
                     const interval = setInterval(async () => {
                         if (annotations.length === 1) {
                             const base64Data = canvasRef.current.toDataURL('image/png');
                             const croppedImage = await cropImage(base64Data, annotations);
                             const extractedAmount = await tesseract(croppedImage);
                             setTotalAmount(extractedAmount);
                             console.log(extractedAmount);  // Affiche la valeur extraite
                         }
                     }, 1000);
                 }*/
            }
        }
    }, [totalAmount]);

    const handleCanvasClick = useCallback((event) => {
        if (annotations.length > 1) {
            console.log(annotations.length)
            const rect = canvasRef.current.getBoundingClientRect();
            const x = event.clientX - rect.left;
            const y = event.clientY - rect.top;
            setClickPosition({x, y});
            let insideAnnotation = false;
            const updatedAnnotations = annotations.filter(annotation => {
                const vertices = annotation.boundingPoly.vertices;
                if (isPointInPolygon({x, y}, vertices)) {
                    insideAnnotation = true;
                    return false;
                }
                return true;
            });
            setAnnotations(updatedAnnotations);
            if (!insideAnnotation) {
                console.log('Point not inside any annotation');
            }
        } else {
            console.log(annotations.length)
            console.log("erreur");
        }
    }, [annotations]);

    const isPointInPolygon = (point, vertices) => {
        let inside = false;
        for (let i = 0, j = vertices.length - 1; i < vertices.length; j = i++) {
            const xi = vertices[i].x, yi = vertices[i].y;
            const xj = vertices[j].x, yj = vertices[j].y;

            const intersect = ((yi > point.y) !== (yj > point.y))
                && (point.x < (xj - xi) * (point.y - yi) / (yj - yi) + xi);
            if (intersect) inside = !inside;
        }
        return inside;
    };

    return (
        <div>
            <div style={{position: 'relative', width: '100%', height: 'auto'}}>
                <video ref={videoRef} autoPlay playsInline style={{display: 'block'}}/>
                <canvas
                    ref={canvasRef}
                    onClick={handleCanvasClick}
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        zIndex: 1,
                    }}
                />
                <div style={{position: 'absolute', bottom: 0, right: 0, display: 'flex', justifyContent: 'center'}}>
                </div>
            </div>
            <button className="iconButton" onClick={toggleCamera}>
                <svg
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    width="19.4371"
                    height="15.3988"
                    style={{margin: 0}}
                >
                    <g>
                        <rect height="15.3988" opacity="0" width="19.4371" x="0" y="0"/>
                        <path
                            d="M2.59043 14.9148L16.4854 14.9148C18.1912 14.9148 19.0758 14.0383 19.0758 12.3484L19.0758 4.3373C19.0758 2.64746 18.1912 1.77891 16.4854 1.77891L14.4203 1.77891C13.8264 1.77891 13.6303 1.65176 13.2701 1.28164L12.6779 0.641212C12.2795 0.231056 11.8623 0 11.0738 0L7.99394 0C7.19746 0 6.78027 0.231056 6.38984 0.641212L5.78965 1.28164C5.4375 1.65176 5.2334 1.77891 4.64746 1.77891L2.59043 1.77891C0.884568 1.77891 0 2.64746 0 4.3373L0 12.3484C0 14.0383 0.884568 14.9148 2.59043 14.9148ZM2.6873 13.2439C2.02987 13.2439 1.66288 12.908 1.66288 12.2115L1.66288 4.48222C1.66288 3.78574 2.02987 3.44179 2.6873 3.44179L5.14511 3.44179C5.82871 3.44179 6.19023 3.30585 6.5746 2.88944L7.13847 2.26327C7.56914 1.79433 7.80253 1.66288 8.49336 1.66288L10.5664 1.66288C11.2572 1.66288 11.4906 1.79608 11.9143 2.258L12.4932 2.88944C12.8695 3.30409 13.2391 3.44179 13.9227 3.44179L16.3885 3.44179C17.0379 3.44179 17.4049 3.78574 17.4049 4.48222L17.4049 12.2115C17.4049 12.908 17.0379 13.2439 16.3885 13.2439Z"
                            fill="#6A6A6A"
                        />
                        <path
                            d="M4.4621 7.9123C4.23144 8.22285 4.33867 8.61328 4.73534 8.61328L5.43105 8.61328C5.80839 10.6008 7.46621 12.0012 9.5539 12.0012C10.433 12.0012 11.2867 11.7195 11.8951 11.2531C12.3033 10.9469 12.4117 10.5004 12.1283 10.1711C11.8512 9.8543 11.458 9.84863 11.0891 10.0988C10.6397 10.4178 10.184 10.6098 9.5539 10.6098C8.23906 10.6098 7.12401 9.75312 6.78573 8.61328L7.47363 8.61328C7.8623 8.61328 7.97577 8.22109 7.74511 7.9123L6.50292 6.24375C6.29531 5.95 5.90839 5.93574 5.67851 6.24375ZM6.96347 5.56269C7.24062 5.8875 7.63378 5.89316 7.99472 5.64296C8.44414 5.308 8.90781 5.13202 9.53789 5.13202C10.8527 5.13202 11.9518 5.98066 12.2981 7.12851L11.5701 7.12851C11.1814 7.12851 11.068 7.51269 11.2986 7.82148L12.5328 9.49004C12.7484 9.78379 13.1354 9.80605 13.3652 9.49004L14.5816 7.82148C14.8123 7.51094 14.7051 7.12851 14.3084 7.12851L13.6527 7.12851C13.2754 5.14101 11.6256 3.74062 9.53789 3.74062C8.65078 3.74062 7.79707 4.02226 7.19667 4.48066C6.78046 4.79492 6.68007 5.23339 6.96347 5.56269Z"
                            fill="#6A6A6A"
                        />
                    </g>
                </svg>
            </button>
            {currentClient === "" ? (
                <p>{totalAmount} {annotations.length}</p>
            ) : (
                <p className="greenText">client: {totalAmount}</p>
            )}

            {annotations.length > 1 ? (
                <p className="redtext">Cliquez sur le Rectangle correspondant au Total à payer pour le client</p>
            ) : (
                <button className="button" onClick={() => reFrame(0)}>Recadrer</button>
            )}
        </div>
    );
};

export default WebcamComponent;

