// firebaseConfig.js
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

// Remplacez par votre propre configuration Firebase
const firebaseConfig = {
    apiKey: "AIzaSyAHey6OhGXiv8RPIVWHw8EX6gFu5_Uj5FU",
    authDomain: "card-cbc64.firebaseapp.com",
    databaseURL: "https://card-cbc64-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "card-cbc64",
    storageBucket: "card-cbc64.appspot.com",
    messagingSenderId: "960126720956",
    appId: "1:960126720956:web:9a3d25fcfba64ea29ee9b9",
    measurementId: "G-4REW8DGRJ6"
};


// Initialisez Firebase
const app = initializeApp(firebaseConfig);

// Initialisez Firestore
const db = getFirestore(app);

export { db };
