import Tesseract from 'tesseract.js';

 export const extractText = async (base64Image) => {
     try {
         const { data: { text } } = await Tesseract.recognize(base64Image, 'fra');
         const filteredText = text.replace(/[^\d.,]/g, '');
         return filteredText;
     } catch (error) {
         console.error('Error during text detection:', error);
         throw error;
     }
 };

