import React, {useState, useEffect} from 'react';
import {doc, onSnapshot, deleteDoc, updateDoc} from 'firebase/firestore';
import WebcamComponent from "../../components/cam/cam";
import {db} from "../../firebase";

 const deleteCurrentClient = async () => {
    const fieldRef = doc(db, 'users', '5FOqoVDPMPWdmyYeZcHeBrbPSlk2');
    try {
        await updateDoc(fieldRef, {
            currentClient: ''
        });
    } catch (error) {
        console.log(error);
    }
}

function Home() {
    const [data, setData] = useState(null);
    const [currentClient, setCurrentClient] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const clientDocRef = doc(db, 'users', '5FOqoVDPMPWdmyYeZcHeBrbPSlk2');

    useEffect(() => {
        const unsubscribe = onSnapshot(clientDocRef, (doc) => {
            if (doc.exists()) {
                const clientData = doc.data();
                if (clientData) {
                    setLoading(false);
                    setCurrentClient(clientData.currentClient);
                } else {
                    setError(true)
                    setData(null);
                }
            } else {
                setError(true)
                setData(null);
            }
        });
        return () => {
            unsubscribe();
        };
    }, []);

    useEffect(() => {
        if (currentClient) {
            const rewardClientRef = doc(db, 'users', '5FOqoVDPMPWdmyYeZcHeBrbPSlk2', 'rewards', `${currentClient}`);

            const rewardUnsubscribe = onSnapshot(rewardClientRef, (rewardDoc) => {
                if (rewardDoc.exists()) {
                    setData(rewardDoc.data());
                } else {

                }
            });

            return () => {
                rewardUnsubscribe();
            };
        }
    }, [currentClient]);




    const deleteReward = async () => {
        console.log(currentClient);
        const docRef = doc(db, 'users', '5FOqoVDPMPWdmyYeZcHeBrbPSlk2', 'rewards', `${currentClient}`);
        try {
            await deleteDoc(docRef);
            await deleteCurrentClient()
            console.log('Document successfully deleted!');
            setData(null);
        } catch (error) {
            setError(true)
            console.error('Error removing document: ', error);
        }
    };

    if (loading) {
        return <div className="centeredText">
            <h1>Chargement en cours</h1>
        </div>;
    }

    if (error) {
        return <div className="centeredRedText">
            <h1>Une erreur s'est produite</h1>
        </div>;
    }

    if (!data) {
        return <div className="container">
            <h2>Rien à signaler...</h2>
            <WebcamComponent currentClient={currentClient}/>
        </div>;
    }

    return (
        <div>
            <div className="backgroundColor">
                <div className="container">
                    <h2>Commande</h2>
                    <div id="output">
                        {Object.entries(data).map(([key, value]) => (
                            <React.Fragment key={key}>
                                <p><span>{value}</span> x {key}</p>
                                <hr/>
                            </React.Fragment>
                        ))}
                    </div>
                    <br/>
                    <button className="button" onClick={deleteReward}>Valider la commande</button>
                </div>
            </div>
        </div>
    );
}

export default Home;
